<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          class="cari-liste-card"
      >

        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">Genel Ayarlar</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="text-right border-0 float-right"
                  variant="info"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
                  @click="ayarUpdate"
              >
                Ayarları Uygula
              </b-button>
            </b-col>
          </div>
        </b-row>
        <b-row class="mt-50">
          <b-col
              cols="12"
              class="mt-1"
          >
            <b-progress
                v-model="value"
                max="100"
                class="w-100"
            />
          </b-col>
        </b-row>
        <hr>
        <b-tabs v-if="ayarlar">
          <hr class="mt-1 mb-0"/>
          <b-tab
              active
              class="mt-1"
              :key="count"
          >
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Kurum Bilgileri</span>
            </template>
            <b-row>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Kurum Kısa Ünvan"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.unvan"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Kurum Tam Ünvan"
                    label-for="basicInput"
                >
                  <b-form-input
                      v-model="ayarlar.tamUnvan"
                      debounce="900"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="İl"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.il"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="İlçe"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.ilce"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Telefon"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.telefon"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="E-Posta"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.ePosta"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Banka Adı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.bankaAdi"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Hesap Ünvanı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.hesapUnvan"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="İban No"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.ibanNo"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Kurum Yetkilisi"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.kurumYetkilisi"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Kurum Yetkilisi Telefon"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.yetkiliTelefon"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Adres"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.adres"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="GPS Kordinatları"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.gpsKoordinat"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Web Site Adresi"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.siteURL"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Çıkış Saati"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.checkOut"/>
                </b-form-group>
              </b-col>
              <hr>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Hava Durumu Widget Bölge"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.weatherBolge"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Hava Durumu Widget İl"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.weatherIl"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Hava Durumu Widget İlçe"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.weatherIlce"/>
                </b-form-group>
              </b-col>
              <b-col cols="6" :key="count">
                <b-form-group
                    label="Header Logo"
                    label-for="basicInput"
                >
                  <b-form-file
                      type="file"
                      placeholder="Dosya Seçiniz"
                      drop-placeholder="Drop file here..."
                      accept=".jpg, .png, .gif, .jpeg"
                      :file-name-formatter="formatNames"
                      @change="onFileSelectedHeader"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" :key="count + 2">
                <b-form-group
                    label="Footer Logo"
                    label-for="basicInput"
                >
                  <b-form-file
                      type="file"
                      placeholder="Dosya Seçiniz"
                      drop-placeholder="Drop file here..."
                      accept=".jpg, .png, .gif, .jpeg"
                      :file-name-formatter="formatNames"
                      @change="onFileSelectedFooter"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab class="mt-1" @click="ePosta()">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>E-Posta Yönetimi</span>
            </template>
            <b-row>
              <b-col cols="12">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="info"
                    class="mb-1"
                    v-b-modal.create-posta
                >
                  E-Posta Oluştur
                </b-button>
                <b-modal
                    id="create-posta"
                    modal-class="modal-success"
                    centered
                    title="E-Posta Oluştur"
                    hide-footer
                >
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                          label="E-Posta"
                          label-for="basicInput"
                      >
                        <b-form-input
                            id="basicInput"
                            autocomplete="off"
                            v-model="mail.ePosta"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                          label="Şifre"
                          label-for="basicInput"
                      >
                        <b-form-input
                            id="basicInput"
                            autocomplete="off"
                            v-model="mail.sifre"
                        />
                      </b-form-group>
                    </b-col>
                    <hr>
                    <b-col cols="12" class="d-flex justify-content-end">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="mr-50"
                          @click="$bvModal.hide('create-posta')"
                      >
                        Kapat
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="ePostaOlustur()"
                          :disabled="!mail.ePosta || !mail.sifre"
                      >
                        Kaydet
                      </b-button>
                    </b-col>
                  </b-row>
                </b-modal>
                <table class="table table-hover table-striped eposta-table"
                       :class="width < 1200 ? 'table-responsive':''"
                >
                  <thead>
                  <tr>
                    <th :style="{backgroundColor:$store.getters.tableTitle,color:'white'}" scope="col">Kullanıcı</th>
                    <th :style="{backgroundColor:$store.getters.tableTitle,color:'white'}" scope="col">Mail</th>
                    <th :style="{backgroundColor:$store.getters.tableTitle,color:'white'}" scope="col">Kullanılan Disk
                      Alanı
                    </th>
                    <th :style="{backgroundColor:$store.getters.tableTitle,color:'white'}" scope="col">Kota</th>
                    <th :style="{backgroundColor:$store.getters.tableTitle,color:'white'}" scope="col">domain</th>
                    <th :style="{backgroundColor:$store.getters.tableTitle,color:'white'}" scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in epostaList.list" :key="item.user">
                    <td>{{ item.user }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item._diskused }}</td>
                    <td>{{ item.humandiskquota }}</td>
                    <td>{{ item.domain }}</td>
                    <td>
                      <b-button
                          variant="gradient-warning"
                          class="btn-icon rounded-circle mr-50"
                          @click="changePasswordUser = item.email"
                          v-b-modal.posta-sifre-degis
                      >
                        <feather-icon icon="LockIcon"/>
                      </b-button>
                      <b-button
                          variant="gradient-danger"
                          class="btn-icon rounded-circle"
                          @click="ePostaSil(item.user)"
                      >
                        <feather-icon icon="XIcon"/>
                      </b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab class="mt-1">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Rezervasyon Ayarları</span>
            </template>
            <b-row>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Eş Zamanlı Rez. Sorgusu"
                    label-for="basicInput"
                >
                  <v-select
                      key="key"
                      v-model="realTime"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="realTimeOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Rez. TC Kimlik Kontrolü"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="tcControl"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="tcControlOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Eş Zamanlı Rez. Zaman Aşım Süresi (dakika)"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.timeOut"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Maksimum Online Rezervasyon Tarihi"
                    label-for="basicInput"
                >
                  <flat-pickr
                      v-model="ayarlar.rezervasyonSonTarih"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Ücretsiz Çocuk Tarifesi"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="ucretsizCocuk"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="ucretsizCocukOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Ücretsiz Çocuk Yaş Aralığı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.ucretsizCocukText"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Ücretli Çocuk Tarifesi"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="ucretliCocuk"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="ucretliCocukOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Ücretli Çocuk Yaş Aralığı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.ucretliCocukText"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Online Sayfa"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="onlineSayfa"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="onlineSayfaOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab class="mt-1">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Web Entegrasyon Ayarları</span>
            </template>
            <b-row>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="SMTP Sunucu"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.smtpServer"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="SMTP Kullanıcı Adı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.smtpUser"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="SMTP Şifresi"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.smtpPwd"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="API User"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.apiURL"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="API Key"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.apiKey"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Pos Mağaza Numarası"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.clientID"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Pos Mağaza Anahtarı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.storeKey"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Sanal Pos Servisi"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="posService"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="sanalPosServisiOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Banka"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="banka"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="bankaOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Pos Başarılı URL"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.okURL"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Pos Başarısız URL"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.failURL"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab class="mt-1">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>E-Fatura Ayarları</span>
            </template>
            <b-row>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="E-Fatura Gönderimi"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="eFaturaGonderimi"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="eFaturaGonderimiOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="E-Fatura Kullanıcı Adı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceUser"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="E-Fatura Kullanıcı Şifresi"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoicePass"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="E Fatura Servis Sağlayıcı"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="eFaturaServisSaglayici"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="eFaturaServisSaglayiciOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Fatura Ünvanı"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceUnvan"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Vergi Dairesi"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceVD"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Vergi Numarası"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceVN"/>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Telefon Numarası"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceTelefon"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Mersis No"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceMersis"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Ticaret Sicil No"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceSicil"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Adres"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceAdres"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="İl"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceIl"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="İlçe"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceIlce"/>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Kapı No"
                    label-for="basicInput"
                >
                  <b-form-input v-model="ayarlar.invoiceKapiNo"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab class="mt-1">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Sosyal Medya</span>
            </template>
            <b-row class="mt-2">
              <b-col
                  lg="4"
                  md="6"
                  sm="12"
                  cols="12"
                  class="mb-1"
              >
                <b-form-group
                    label="İnstagram"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      placeholder="instagram url"
                      autocomplete="off"
                      v-model="ayarlar.instagram"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="4"
                  md="6"
                  sm="12"
                  cols="12"
                  class="mb-1"
              >
                <b-form-group
                    label="Facebook"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      placeholder="facebook url"
                      autocomplete="off"
                      v-model="ayarlar.facebook"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="4"
                  md="6"
                  sm="12"
                  cols="12"
                  class="mb-1"
              >
                <b-form-group
                    label="Twitter"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      placeholder="twitter url"
                      autocomplete="off"
                      v-model="ayarlar.twitter"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab class="mt-1">
            <template #title>
              <feather-icon icon="ListIcon"/>
              <span>Tema Ayarları</span>
            </template>
            <b-row>
              <b-col lg="2" md="2" sm="12" cols="12">
                <b-form-group
                    label="Web Site Teması"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="tema"
                      :components="{ OpenIndicator, Deselect }"
                      label="adi"
                      :options="temaListesi"
                  />
                </b-form-group>
                <b-form-group
                    v-if="tema"
                    label="Renk Seçiniz"
                    label-for="basicInput"
                >
                  <v-select
                      v-model="temaRenk"
                      :components="{ OpenIndicator, Deselect }"
                      label="adi"
                      :options="temaRenkData"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="10"
                  md="10"
                  sm="12"
                  cols="12"
                  class="text-center"
              >
                <b-img
                    v-if="tema && temaRenk"
                    :src="`${$store.state.API_URL}userFiles/Upload/${tema.adi || tema}/${temaRenk}.png`"
                    fluid
                    alt="Responsive image"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card-actions>
      <b-modal
          id="posta-sifre-degis"
          modal-class="modal-primary"
          centered
          title="Şifre Değiştir"
          hide-footer
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
                label="Yeni Şifre"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  autocomplete="off"
                  v-model="yeniSifre"
              />
            </b-form-group>
          </b-col>
          <hr>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-50"
                @click="$bvModal.hide('posta-sifre-degis')"
            >
              Kapat
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="!yeniSifre"
                @click="ePostaSifreDegis()"
            >
              Kaydet
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import {
  BImg,
  BRow,
  BFormFile,
  BProgress,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BTabs,
  BTab,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BFormGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vSelect from 'vue-select'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr

flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    BCardActions,
    BImg,
    BFormFile,
    BProgress,
    flatPickr,
    vSelect,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BBreadcrumb,
    BTabs,
    BTab,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      yeniSifre: null,
      changePasswordUser: null,
      mail: {
        ePosta: null,
        sifre: null,
      },
      count: 0,
      tema: '',
      temaRenk: '',
      temaRenkData: [],
      degismeyenData: [],
      degismeyenDataTema: [],
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      footerLogo: [],
      headerLogo: [],
      value: 0,
      eFaturaGonderimi: null,
      eFaturaServisSaglayici: null,
      realTime: null,
      tcControl: null,
      ucretsizCocuk: null,
      ucretliCocuk: null,
      posService: null,
      banka: null,
      realTimeOptions: [
        {
          title: 'Kapalı',
          key: 0
        },
        {
          title: 'Açık',
          key: 1
        },
      ],
      eFaturaGonderimiOptions: [
        {
          title: 'Kapalı',
          key: 0
        },
        {
          title: 'Açık',
          key: 1
        },
      ],
      eFaturaServisSaglayiciOptions: [
        {
          title: 'UyumSoft',
          key: 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi',
        },
        {
          title: 'UyumSoft Kurumsal',
          key: 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi',
        },
        {
          title: 'UyumSoft Test API',
          key: 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi',
        },
        {
          title: 'EDM Entegratör',
          key: 'https://portal2.edmbilisim.com.tr/EFaturaEDM/EFaturaEDM.svc?singleWsdl',
        },
        {
          title: 'EDM Entegratör Test API',
          key: 'https://test.edmbilisim.com.tr/EFaturaEDM21ea/EFaturaEDM.svc?singleWsdl',
        },
      ],
      tcControlOptions: [
        {
          title: 'Kapalı',
          key: 0
        },
        {
          title: 'Açık',
          key: 1
        },
      ],
      ucretsizCocukOptions: [
        {
          title: 'Kapalı',
          key: 0
        },
        {
          title: 'Açık',
          key: 1
        },
      ],
      ucretliCocukOptions: [
        {
          title: 'Kapalı',
          key: 0
        },
        {
          title: 'Açık',
          key: 1
        },
      ],
      sanalPosServisiOptions: [
        {
          title: 'Kapalı',
          key: 0
        },
        {
          title: 'Açık',
          key: 1
        },
      ],
      bankaOptions: [
        {
          title: 'Halk Bank',
          key: 'https://sanalpos.halkbank.com.tr'
        },
        {
          title: 'Ziraat Bank',
          key: 'https://sanalpos2.ziraatbank.com.tr'
        },
      ],
      onlineSayfa: {
        title: 'Özet Görünüm',
        key: 0,
      },
      onlineSayfaOptions: [
        {
          title: 'Özet Görünüm',
          key: 0,
        },
        {
          title: 'Detaylı Görünüm',
          key: 1,
        },
      ],
    }
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  computed: {
    epostaList() {
      return this.$store.getters.GET_EPOSTA_LIST
    },
    temaListesi() {
      return this.$store.getters.GET_TEMA_LISTESI
    },
    ayarlar() {
      return this.$store.getters.webAyar
    },
  },
  watch: {
    tema(newVal) {
      this.temaRenkData = newVal.renkSecenek
    },
  },
  created() {
    this.$store
        .dispatch('sistemAyar')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          this.degismeyenData = { ...res.ayarlar }
          this.tema = res.ayarlar.tema
          this.temaRenk = res.ayarlar.temaRenk
          this.temaRenkData = this.tema.renkSecenek
          this.$store.dispatch('temaList')
              .then(resTema => {
                this.degismeyenDataTema = resTema.temaListesi
                const veri = resTema.temaListesi.find(a => a.adi == this.tema)
                this.temaRenkData = veri.renkSecenek
              })
          const tcKontrol = res.ayarlar.checkTCKN
          const { ucretsizCocuk } = res.ayarlar
          const { realTime } = res.ayarlar
          const { ucretliCocuk } = res.ayarlar
          const { posService } = res.ayarlar
          const { onlineSayfa } = res.ayarlar
          const banka = res.ayarlar.postAction
          const eFatura = res.ayarlar.invoiceService
          const eFaturaServisSaglayici = res.ayarlar.invoiceServer
          this.tcControl = tcKontrol == 1
              ? {
                title: 'Açık',
                key: 1
              }
              : {
                title: 'Kapalı',
                key: 0
              }
          if (eFaturaServisSaglayici == 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi') {
            this.eFaturaServisSaglayici = {
              title: 'UyumSoft',
              key: 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi',
            }
          }
          if (eFaturaServisSaglayici == 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi') {
            this.eFaturaServisSaglayici = {
              title: 'UyumSoft Kurumsal',
              key: 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi',
            }
          }
          if (eFaturaServisSaglayici == 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi') {
            this.eFaturaServisSaglayici = {
              title: 'UyumSoft Test API',
              key: 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi',
            }
          }
          if (eFaturaServisSaglayici == 'https://portal2.edmbilisim.com.tr/EFaturaEDM/EFaturaEDM.svc?singleWsdl') {
            this.eFaturaServisSaglayici = {
              title: 'EDM Entegratör',
              key: 'https://portal2.edmbilisim.com.tr/EFaturaEDM/EFaturaEDM.svc?singleWsdl',
            }
          }

          if (eFaturaServisSaglayici == 'https://test.edmbilisim.com.tr/EFaturaEDM21ea/EFaturaEDM.svc?singleWsdl') {
            this.eFaturaServisSaglayici = {
              title: 'EDM Entegratör Test API',
              key: 'https://test.edmbilisim.com.tr/EFaturaEDM21ea/EFaturaEDM.svc?singleWsdl',
            }
          }

          this.eFaturaGonderimi = eFatura == 1
              ? {
                title: 'Açık',
                key: 1
              }
              : {
                title: 'Kapalı',
                key: 0
              }
          this.realTime = realTime == 1
              ? {
                title: 'Açık',
                key: 1
              }
              : {
                title: 'Kapalı',
                key: 0
              }
          this.ucretsizCocuk = ucretsizCocuk == 1
              ? {
                title: 'Açık',
                key: 1
              }
              : {
                title: 'Kapalı',
                key: 0
              }
          this.ucretliCocuk = ucretliCocuk == 1
              ? {
                title: 'Açık',
                key: 1
              }
              : {
                title: 'Kapalı',
                key: 0
              }
          this.posService = posService == 1
              ? {
                title: 'Açık',
                key: 1
              }
              : {
                title: 'Kapalı',
                key: 0
              }
          this.banka = banka == 'https://sanalpos2.ziraatbank.com.tr'
              ? {
                title: 'Ziraat Bank',
                key: 'https://sanalpos2.ziraatbank.com.tr',
              }
              : {
                title: 'Halk Bank',
                key: 'https://sanalpos.halkbank.com.tr'
              }
          this.onlineSayfa = onlineSayfa == 1
              ? {
                title: 'Detaylı Görünüm',
                key: 1,
              }
              : {
                title: 'Özet Görünüm',
                key: 0,
              }
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearSistemAyarSave')
  },
  methods: {
    ePosta() {
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('firmaKodu', localStorage.getItem('otelFirmaKodu'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'ePostaListesi')
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => this.$store.commit({
            type: 'setEPostaList',
            list: response.data.data.data,
          }))
    },
    ePostaSil(user) {
      this.$swal({
        title: 'Eminmisiniz ? ',
        text: 'Bu işlem geri alınamaz',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const fd = new FormData()
              fd.append('crm_token', localStorage.getItem('otelUserToken'))
              fd.append('firmaKodu', localStorage.getItem('otelFirmaKodu'))
              fd.append('serviceName', 'web')
              fd.append('methodName', 'ePostaSil')
              fd.append('ePosta', user)
              axios
                  .post(store.state.POST_URL, fd, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  .then(() => this.ePosta(), this.$swal({
                    icon: 'success',
                    title: 'Başarılı',
                    text: 'E-Posta Silindi',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  }))
            }
          })
    },
    ePostaSifreDegis() {
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('firmaKodu', localStorage.getItem('otelFirmaKodu'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'ePostaSifre')
      fd.append('ePosta', this.changePasswordUser)
      fd.append('sifre', this.yeniSifre)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            if (response.data.result.status === 200) {
              this.ePosta()
              this.$bvModal.hide('posta-sifre-degis')
              this.yeniSifre = null
              this.changePasswordUser = null
              const mesaj = this.$store.getters.notificationSettings(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
            } else {
              const mesaj = this.$store.getters.notificationSettings(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            const mesaj = this.$store.getters.notificationSettings(error.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    ePostaOlustur() {
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('firmaKodu', localStorage.getItem('otelFirmaKodu'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'ePostaOlustur')
      fd.append('ePosta', this.mail.ePosta || '')
      fd.append('sifre', this.mail.sifre || '')
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            if (response.data.result.status === 200) {
              this.ePosta()
              this.$bvModal.hide('create-posta')
              this.mail.ePosta = null
              this.mail.sifre = null
              const mesaj = this.$store.getters.notificationSettings(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              })
            } else {
              const mesaj = this.$store.getters.notificationSettings(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            const mesaj = this.$store.getters.notificationSettings(error.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    ayarUpdate() {
      const guncellenenKeyler = {}
      Object.entries(this.degismeyenData)
          .forEach(entry => {
            const [key, value] = entry
            if (key != 'footerLogo' && key != 'headerLogo') {
              if (this.ayarlar[key] && this.ayarlar[key] != value) {
                guncellenenKeyler[key] = this.ayarlar[key]
              }
            }
          })
      const fd = new FormData()
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'webAyar')
      fd.append('ayarGuncelle', 1)
      Object.entries(guncellenenKeyler)
          .forEach(entry => {
            const [key, value] = entry
            fd.append(`updateList[${key}]`, value)
          })
      // this.footerLogo ? this.footerLogo.forEach(element => {
      //   fd.append('footerLogo[]', element)
      // }) : fd.append('footerLogo[]', '')
      // this.headerLogo ? this.headerLogo.forEach(element => {
      //   fd.append('headerLogo[]', element)
      // }) : fd.append('headerLogo[]', '')
      fd.append('footerLogo', this.footerLogo[0] || '')
      fd.append('headerLogo', this.headerLogo[0] || '')
      fd.append('updateList[realTime]', this.realTime.key)
      fd.append('updateList[checkTCKN]', this.tcControl.key)
      fd.append('updateList[ucretsizCocuk]', this.ucretsizCocuk.key)
      fd.append('updateList[ucretliCocuk]', this.ucretliCocuk.key)
      fd.append('updateList[posService]', this.posService.key)
      fd.append('updateList[postAction]', this.banka.key)
      fd.append('updateList[invoiceService]', this.eFaturaGonderimi.key)
      fd.append('updateList[invoiceServer]', this.eFaturaServisSaglayici.key)
      fd.append('updateList[tema]', this.tema.adi || this.tema)
      fd.append('updateList[temaRenk]', this.temaRenk)
      fd.append('updateList[onlineSayfa]', this.onlineSayfa.key)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.spinner = false
            this.degismeyenData = { ...res.data.ayarlar }
            this.tema = res.data.ayarlar.tema
            this.temaRenk = res.data.ayarlar.temaRenk
            this.temaRenkData = this.tema.renkSecenek
            this.$store.dispatch('temaList')
                .then(resTema => {
                  this.degismeyenDataTema = resTema.temaListesi
                  const veri = resTema.temaListesi.find(a => a.adi == this.tema)
                  this.temaRenkData = veri.renkSecenek
                })
            const tcKontrol = res.data.ayarlar.checkTCKN
            const { ucretsizCocuk } = res.data.ayarlar
            const { realTime } = res.data.ayarlar
            const { ucretliCocuk } = res.data.ayarlar
            const { posService } = res.data.ayarlar
            const { onlineSayfa } = res.data.ayarlar
            const banka = res.data.ayarlar.postAction
            const eFatura = res.data.ayarlar.invoiceService
            const eFaturaServisSaglayici = res.data.ayarlar.invoiceServer
            this.tcControl = tcKontrol == 1
                ? {
                  title: 'Açık',
                  key: 1
                }
                : {
                  title: 'Kapalı',
                  key: 0
                }
            if (eFaturaServisSaglayici == 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi') {
              this.eFaturaServisSaglayici = {
                title: 'UyumSoft',
                key: 'http://efatura.uyumsoft.com.tr/api/BasicIntegrationApi',
              }
            }
            if (eFaturaServisSaglayici == 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi') {
              this.eFaturaServisSaglayici = {
                title: 'UyumSoft Kurumsal',
                key: 'http://edonusumapi.uyum.com.tr/api/BasicIntegrationApi',
              }
            }
            if (eFaturaServisSaglayici == 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi') {
              this.eFaturaServisSaglayici = {
                title: 'UyumSoft Test API',
                key: 'http://efatura-test.uyumsoft.com.tr/api/BasicIntegrationApi',
              }
            }
            if (eFaturaServisSaglayici == 'https://portal2.edmbilisim.com.tr/EFaturaEDM/EFaturaEDM.svc?singleWsdl') {
              this.eFaturaServisSaglayici = {
                title: 'EDM Entegratör',
                key: 'https://portal2.edmbilisim.com.tr/EFaturaEDM/EFaturaEDM.svc?singleWsdl',
              }
            }
            if (eFaturaServisSaglayici == 'https://test.edmbilisim.com.tr/EFaturaEDM21ea/EFaturaEDM.svc?singleWsdl') {
              this.eFaturaServisSaglayici = {
                title: 'EDM Entegratör Test API',
                key: 'https://test.edmbilisim.com.tr/EFaturaEDM21ea/EFaturaEDM.svc?singleWsdl',
              }
            }
            this.eFaturaGonderimi = eFatura == 1
                ? {
                  title: 'Açık',
                  key: 1
                }
                : {
                  title: 'Kapalı',
                  key: 0
                }
            this.realTime = realTime == 1
                ? {
                  title: 'Açık',
                  key: 1
                }
                : {
                  title: 'Kapalı',
                  key: 0
                }
            this.ucretsizCocuk = ucretsizCocuk == 1
                ? {
                  title: 'Açık',
                  key: 1
                }
                : {
                  title: 'Kapalı',
                  key: 0
                }
            this.ucretliCocuk = ucretliCocuk == 1
                ? {
                  title: 'Açık',
                  key: 1
                }
                : {
                  title: 'Kapalı',
                  key: 0
                }
            this.posService = posService == 1
                ? {
                  title: 'Açık',
                  key: 1
                }
                : {
                  title: 'Kapalı',
                  key: 0
                }
            this.banka = banka == 'https://sanalpos2.ziraatbank.com.tr'
                ? {
                  title: 'Ziraat Bank',
                  key: 'https://sanalpos2.ziraatbank.com.tr',
                }
                : {
                  title: 'Halk Bank',
                  key: 'https://sanalpos.halkbank.com.tr'
                }
            this.onlineSayfa = onlineSayfa == 1
                ? {
                  title: 'Detaylı Görünüm',
                  key: 1,
                }
                : {
                  title: 'Özet Görünüm',
                  key: 0,
                }
            this.value = 0
            if (res.data.result.status == 200) {
              this.headerLogo = []
              this.footerLogo = []
              this.value = 0
              this.count++
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            } else {
              this.value = 0
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.value = 0
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFileSelectedHeader(event) {
      event.target.files.forEach(image => {
        this.headerLogo.push(image)
      })
    },
    onFileSelectedFooter(event) {
      event.target.files.forEach(image => {
        this.footerLogo.push(image)
      })
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} resim seçildi`
    },
  },
}
</script>

<style scoped>
.progress {
  height: 0.557rem !important;
}

.just-end {
  justify-content: end !important;
  display: flex;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.eposta-table th {
  padding: 0.6rem !important;
}

.eposta-table td {
  padding: 0.6rem !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
